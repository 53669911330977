import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./SustainReport.module.scss";
import VisuallLoading from "components/common/VisuallLoading/VisuallLoading";
import { OptionModel, RequestType } from "models/baseModel";
import { useMutation } from "@tanstack/react-query";
import Dropdown from "components/base/Dropdown/Dropdown";
import LinkIconButton from "components/button/LinkIconButton/LinkIconButton";
import {
  MopsSustainReportOldDataModel,
  SustainReportModel,
  SustainReportRequest2OptionModel,
  SustainReportRequestModel,
  SustainReportResultModel,
} from "models/inquiry/sustainReportModel";
import { normalDate, normalDate3 } from "utils/dateTimeFormat";
import toast from "react-hot-toast";
import SustainReportService from "services/inquiry/sustainReport/SustainReportService";
import IconButton from "components/button/IconButton/IconButton";
import mopsEsgService from "services/inquiry/infoDisclosure/mopsEsgService";
import { MarketOp } from "models/optionModel";
import EmptySearchPage, {
  EmptySearchPageProps,
} from "components/otherModule/EmptySearchPage/EmptySearchPage";
import useSearchParamStore from "state/useSearchParamStore";
import { useLocation, useSearchParams } from "react-router-dom";
import { CommonService } from "services/common/commonService";
import { CSVLink } from "react-csv";
import { ResponseCode } from "models/responseCodeModel";
import { error2Alert } from "utils/otherToast";
import ProblemReportModal, {
  ProblemReportModalProps,
} from "components/otherModule/ProblemReportModal/ProblemReportModal";
import { PrintService } from "services/common/printService";
import {
  MopsCompanyNameListModel,
  MopsIndustryNameListModel,
} from "models/inquiry/infoDisclosure/mopsEsgModel";
import { submitGAEvent } from "services/common/GA";
import { useTranslation } from "react-i18next";

// #region 主頁面 =============================

interface SustainReportProps {}

const SustainReport: FC<SustainReportProps> = () => {
  // #region 參數
  // 是否顯示結果
  const [showResult, setShowResult] = useState<any>({
    show: false,
    print: false,
  });
  // 取得搜尋參數
  const [queryParams, setSearchParams] = useSearchParams();
  const [hide, setHide] = useState<boolean>(false); //left box收合
  const [openAll, setOpenAll] = useState<boolean>(false);
  const { t } = useTranslation();
  const [empty, setEmpty] = useState<EmptySearchPageProps>();
  const [hideTool, setHideTool] = useState<boolean>(true);
  const request = useRef<SustainReportRequest2OptionModel>({
    year: null,
    marketType: null,
    industryNameList: [],
    companyCodeList: [],
  });
  const [data, setData] = useState<SustainReportModel[] | null>(null);
  // 開分頁暫存的結果
  const [tmpResult, setTmpResult] = useState<SustainReportResultModel>();
  // 是否為舊版資訊
  const [oldVersion, setOldVersion] = useState<boolean>(false);
  // 儲存filter參數
  const {
    sustainReportResult,
    setSustainReportResult,
    clearSustainReportResult,
  } = useSearchParamStore();
  const location = useLocation();
  const switchView = [
    {
      name: t("FORM"),
      value: "apply-indicator",
      showAll: false,
      img: "section-icon",
    },
    {
      name: t("BLOCK"),
      value: "all-indicator",
      showAll: true,
      img: "block-icon",
    },
  ];
  useEffect(() => {
    // 初始化完翻譯再set
    setEmpty({
      word: t("PLEASE_ENTER_SEARCH_CONDITION"),
    });
  }, [t]);
  // #endregion 參數

  // #region 事件

  // 搜尋區塊隱藏
  const handleHide = () => {
    setHide((prev) => (prev = !prev));
  };

  //#region 取得清單資料
  /** 2023(含之後) */
  const { mutate: getReportsMutation, isPending: tablePending } = useMutation({
    mutationFn: (data: SustainReportRequest2OptionModel) =>
      SustainReportService.getSustainReports(data),
    onSuccess: (res) => {
      clearEmpty();
      if (res.code === ResponseCode.SuccessOK && res.success) {
        if (res.data && res.data.length > 0) {
          setOldVersion(false);
          setHideTool(false);
          setNewDataFlow(res.data);
        } else {
          setData([]);
          setEmpty({
            ...empty,
            word: t("NO_INFORMATION_FOUND"),
            url: null,
          });
        }
        //- ga event
        submitGAEvent("SustainReport_Inquiry");
      } else {
        setData([]);
        setEmpty({
          ...empty,
          word: res.message,
          url: null,
        });
      }
    },
    onError: (err) => {
      clearEmpty();
      toast.error(err.message);
      setEmpty({
        ...empty,
        word: t("NO_INFORMATION_FOUND"),
      });
    },
  });

  /** 設置新年度資料初始 */
  const setNewDataFlow = (datas: SustainReportModel[]) => {
    datas.forEach((p) => {
      p.twCommencementDate = normalDate3(p.twCommencementDate);
      p.twRevisionDate = normalDate3(p.twRevisionDate);
      p.enCommencementDate = normalDate3(p.enCommencementDate);
      p.enRevisionDate = normalDate3(p.enRevisionDate);
    });
    setData([...datas]);
  };

  // 清除空白
  const clearEmpty = () => {
    setHideTool(true);
    setData([]);
  };

  /** 舊版清單(2022 (含以下)) */
  const { mutate: getMopsSustainReportOldDataList, isPending: tablePending2 } =
    useMutation({
      mutationFn: (data: SustainReportRequest2OptionModel) =>
        SustainReportService.getMopsSustainReportOldDataList(data),
      onSuccess: (res) => {
        clearEmpty();
        if (res.code === ResponseCode.SuccessOK && res.success) {
          if (res.data && res.data.length > 0) {
            setOldVersion(true);
            // console.log(res.data);
            setHideTool(false);
            transOldDataToNewData(res.data);
          } else {
            setData([]);
            setEmpty({
              ...empty,
              word: t("NO_INFORMATION_FOUND"),
              url: null,
            });
          }

          submitGAEvent("SustainReport_Inquiry");
        } else {
          setData([]);
          setEmpty({
            ...empty,
            word: res.message,
            url: null,
          });
        }
      },
      onError: (err) => {
        clearEmpty();
        toast.error(err.message);
        setEmpty({
          ...empty,
          word: t("NO_INFORMATION_FOUND"),
        });
      },
    });

  /** 將舊資料轉為新資料格式 */
  const transOldDataToNewData = (oldDatas: MopsSustainReportOldDataModel[]) => {
    if (request.current) {
      /** 依照不同年度 */
      let year = request.current.year || 0;
      let newDatas: SustainReportModel[] = [];
      if (request.current && year !== null && year !== undefined) {
        // 舊年度資料轉換
        oldDatas.forEach((oldData) => {
          try {
            let newData: SustainReportModel = {
              id: "",
              code: "",
              name: "",
              shortName: "",
              enName: "",
              reason: "",
              sector: "",
              reportingInterval: "",
              complianceNotes: "",
              twDocLink: "",
              twFirstReportDownloadUrl: "",
              twFirstReportDownloadId: null,
              twCommencementDate: null,
              twEditReportDownloadId: null,
              enDocLink: "",
              enFirstReportDownloadUrl: "",
              enFirstReportDownloadId: null,
              enCommencementDate: null,
              enEditReportDownloadId: null,
              contactInfo: "",
              hide: false,
              valiProvider: null,
              thirdStd: "",
              partnerOrganization: null,
              stdCompliance: null,
              accOptionType: null,
              enStatus: null,
              twStatus: null,
              twRevisionDate: null,
              enRevisionDate: null,
              fdate: null,
            };
            //#region 舊年度共同修正欄位
            newData.enStatus = oldData.enStatus;
            newData.twStatus = oldData.twStatus;
            newData.code = oldData.companY_ID;
            newData.name = oldData.companY_ABBR_NAME;
            newData.shortName = oldData.companY_ABBR_NAME || "";
            newData.reason = oldData.sub;
            newData.reportingInterval =
              oldData.duratioN_B && oldData.duratioN_E
                ? `${normalDate3(oldData.duratioN_B)}~${normalDate3(
                    oldData.duratioN_E
                  )}`
                : "";
            newData.complianceNotes = oldData.cS_A1;

            newData.twDocLink = oldData.weB_INFO;
            newData.twFirstReportDownloadId = concatenateOldUrl(
              oldData.filE_NAME
            );
            newData.twCommencementDate = normalDate3(oldData.useR_DATE1);
            newData.twEditReportDownloadId = concatenateOldUrl(
              oldData.filE_NAME2
            );
            newData.twRevisionDate = normalDate3(oldData.useR_DATE2);

            newData.enDocLink = oldData.enG_WEB;
            newData.enFirstReportDownloadId = concatenateOldUrl(
              oldData.filE_ENAME
            );
            newData.enCommencementDate = normalDate3(oldData.useR_EDATE1);
            newData.enEditReportDownloadId = concatenateOldUrl(
              oldData.filE_ENAME2
            );
            newData.enRevisionDate = normalDate3(oldData.useR_EDATE2);

            newData.fdate = normalDate3(oldData.fdate);
            newData.contactInfo = oldData.contacT_INFO;
            newData.note = oldData.memo;
            //#endregion
            if (year <= 2013) {
              // (特殊) 驗證單位 -> 第三方驗證單位, 驗證方法 -> 採用標準 (前端自行轉換)
              newData.valiProvider = oldData.certi;
              newData.thirdStd = oldData.amethod;
            } else if (year >= 2014 && year <= 2019) {
              // (特殊) 保證/確信 -> 會計師確信
              newData.stdCompliance = oldData.surE_A;
            } else if (year >= 2020 && year <= 2022) {
              // 第三方驗證單位
              newData.valiProvider = oldData.surE_A;
              // 無單位名稱暫時用partnerOrganization 取代
              newData.partnerOrganization = oldData.acC_A;
              // 第三方採用標準
              newData.thirdStd = oldData.acC_S;
              // (特殊) 英文名稱、產業別
              newData.enName = oldData.englisH_ABBR_NAME || "";
              newData.sector = oldData.name || "";
            }
            newDatas.push(newData);
          } catch (ex) {
            console.log("code:", oldData.companY_ID);
            console.log(ex);
          }
        });
      }
      setData([...newDatas]);
    } else {
      setData([]);
    }
  };

  /** 串接舊版下載檔案資料 */
  const concatenateOldUrl = (fileName: string | null) => {
    if (fileName) {
      return `https://mops.twse.com.tw/server-java/FileDownLoad?step=9&filePath=/home/html/nas/protect/t100/&fileName=${fileName}`;
    }
    return fileName;
  };

  //#endregion

  const handleToggleAll = () => {
    setOpenAll(!openAll);
  };

  // #endregion 事件

  //#region 開新分頁相關

  useEffect(() => {
    handleExcelFlow();
  }, [data]);

  useEffect(() => {
    // 點別的模組 監聽路由參數，搜尋空 須改回false
    if (!location.search) {
      setShowResult({
        ...showResult,
        show: false,
      });
    }
  }, [location]);

  // query參數拿出
  useEffect(() => {
    const newWindow = queryParams.get("newWindow");
    const isPrint = queryParams.get("isPrint");

    if (newWindow || isPrint) {
      // 為了讓重整or 點別的模組可以區分路由有改變的參數(單純路由變化)
      queryParams.set("result", "y");

      const newObj = {
        show: false,
        print: false,
      };
      // 分頁
      if (newWindow === "y" && sustainReportResult) {
        newObj.show = true;
        queryParams.delete("newWindow");
        setTmpResult({
          ...sustainReportResult,
        });
        clearSustainReportResult();
      }

      // 影印
      if (isPrint === "y") {
        newObj.print = true;
        // 刪掉print
        queryParams.delete("isPrint");
        setTimeout(() => {
          window.print();
        }, 50);
      }
      setSearchParams(queryParams);
      setShowResult({
        ...showResult,
        ...newObj,
      });
    }
  }, [queryParams]);

  /** 處理exce 資料flow */
  const handleExcelFlow = () => {
    let tmp: any[] = [];
    if (data) {
      data?.forEach((d) => {
        tmp.push(getDataValArr(d));
      });
    } else if (tmpResult && tmpResult.data) {
      tmpResult.data.forEach((d: SustainReportModel) => {
        tmp.push(getDataValArr(d));
      });
    }

    // 避免有特殊轉譯符號
    let newTmp: any[] = [];
    // 避免有特殊轉譯符號
    tmp.forEach((item) => {
      if (item) {
        let items: any[] = [];
        item = item.forEach((text: any) => {
          items.push(CommonService.sanitizeCSVField(text));
        });
        newTmp.push(items);
      }
    });

    setSustainReportResult({
      data: data,
      csvData: [...newTmp],
      header: [...getDataTitleArr()],
    });
  };

  /** 取得資料title 陣列 */
  const getDataTitleArr = (): string[] => {
    let standTitles = [
      t("COMPANY_CODE"),
      t("COMPANY_NAME"),
      t("ENGLISH_ABBREVIATION"),
      t("REASON_FOR_DECLARATION"),
      t("INDUSTRY_CATEGORY"),
      t("PERIOD_COVERED_BY_REPORT"),
      t("COMPILED_ACCORDING_TO_THE_GUIDELINES"),
      t("THIRD_PARTY_VERIFICATION_UNIT"),
      t("THIRD_PARTY_VERIFICATION_USING_STANDARDS"),
      t("ACCOUNTANT_CONVINCED_VERIFICATION_UNIT"),
      t("ACCOUNTANT_CONVINCED_VERIFICATION_UNIT_NAME"),
      t("ACCOUNTANT_CONVINCED_VERIFICATION_USING_STANDARDS"),
    ];

    // 固定後面有的
    let baseColumns = [
      t("REPORT_URL"),
      t("REPORT_FILE"),
      t("UPLOAD_DATE"),
      t("AMENDED_REPORT"),
      t("AMENDED_REPORT_UPLOAD_DATE"),
      t("REPORT_EN_VERSION_URL"),
      t("REPORT_EN_VERSION_FILE"),
      t("EN_VERSION_UPLOAD_DATE"),
      t("EN_VERSION_AMENDED_REPORT"),
      t("EN_VERSION_AMENDED_REPORT_UPLOAD_DATE"),
      t("REPORT_CONTACT_INFORMATION"),
      t("NOTE"),
    ];
    let allColumns: string[] = [];
    if (request.current && request.current.year) {
      let year = request.current.year;

      if (year <= 2013) {
        standTitles = [
          t("COMPANY_CODE"),
          t("COMPANY_NAME"),
          t("REPORT_COMPLETE_DATE"),
          t("PERIOD_COVERED_BY_REPORT"),
          t("COMPILED_ACCORDING_TO_THE_GUIDELINES"),
          t("CERTIFICATION_UNIT"),
          t("VERIFICATION_METHOD"),
        ];
      } else if (year >= 2014 && year <= 2019) {
        standTitles = [
          t("COMPANY_CODE"),
          t("COMPANY_NAME"),
          t("REASON_FOR_DECLARATION"),
          t("REPORT_COMPLETE_DATE"),
          t("PERIOD_COVERED_BY_REPORT"),
          t("COMPILED_ACCORDING_TO_THE_GUIDELINES"),
          `${t("GUARANTEE")}/${t("CONFIRMATION")}`,
        ];
      } else if (year >= 2020 && year <= 2022) {
        standTitles = standTitles.filter(
          (p) => p !== t("ACCOUNTANT_CONVINCED_VERIFICATION_USING_STANDARDS")
        );
      }
      allColumns = [...standTitles, ...baseColumns];
    }
    return allColumns;
  };

  /** 取得資料值陣列 */
  const getDataValArr = (item: SustainReportModel): (string | null)[] => {
    let standTitles = [
      item.code,
      item.name,
      item.enName,
      item.reason,
      item.sector,
      item.reportingInterval,
      item.complianceNotes,
      item.valiProvider,
      item.thirdStd,
      item.partnerOrganization,
      item.stdCompliance,
      item.accOptionType,
    ];

    // 固定後面有的
    let baseColumns = [
      item.twDocLink,
      item.twFirstReportDownloadUrl,
      item.twCommencementDate,
      item.twEditReportDownloadUrl!,
      item.twRevisionDate,
      item.enDocLink,
      item.enFirstReportDownloadUrl,
      item.enCommencementDate,
      item.enEditReportDownloadUrl!,
      item.enRevisionDate,
      item.contactInfo,
      item.note!,
    ];
    let allColumns: (string | null)[] = [];
    if (request.current && request.current.year) {
      let year = request.current.year;

      if (year <= 2013) {
        standTitles = [
          item.code,
          item.name,
          item.fdate,
          item.reportingInterval,
          item.complianceNotes,
          item.valiProvider,
          item.thirdStd,
        ];
      } else if (year >= 2014 && year <= 2019) {
        standTitles = [
          item.code,
          item.name,
          item.reason,
          item.fdate,
          item.reportingInterval,
          item.complianceNotes,
          item.stdCompliance,
        ];
      } else if (year >= 2020 && year <= 2022) {
        // 移除掉最後一個會計師意見類型
        standTitles.pop();
      }
      allColumns = [...standTitles, ...baseColumns];
    }
    return allColumns;
  };

  // 處理新視窗
  const handleNewWindow = (print: boolean) => {
    handleExcelFlow();
    PrintService.openNewWindowByParam("newWindow=y", print);
  };
  //#endregion

  //#region 問題回報modal
  const [problemModal, setProblemModal] = useState<ProblemReportModalProps>({
    show: false,
    handleClose: () => {
      setProblemModal({
        ...problemModal,
        show: false,
      });
    },
    size: "sm",
  });
  //#endregion
  return (
    <>
      {!showResult.show ? (
        <div className="no-nav-content-box">
          <div className={styles["sustainReport-box"]}>
            {(tablePending || tablePending2) && <VisuallLoading />}
            {/* 左區塊 */}
            <div className={"left-box " + (hide ? "hide" : "")}>
              <div style={{ display: hide ? "none" : "block" }}>
                <div className="toggle-box">
                  <div className="main-title">{t("SEARCH_CONDITION")}</div>
                  <img
                    alt=""
                    src="/assets/images/buttonIcon/toggle-left-icon.svg"
                    onClick={handleHide}
                  />
                </div>
                <div className="search-item-box">
                  <FilterComponent
                    searchChange={(e) => {
                      request.current = e;
                      if (
                        request.current.year &&
                        request.current.year >= 2023
                      ) {
                        getReportsMutation(request.current);
                      } else {
                        getMopsSustainReportOldDataList(request.current);
                      }
                    }}
                  />
                </div>
              </div>

              {hide && (
                <div className="img-tool">
                  <img
                    alt=""
                    src="/assets/images/buttonIcon/toggle-right-icon.svg"
                    onClick={handleHide}
                  />
                  {/* <img
                  alt=""
                  src="/assets/images/buttonIcon/filter-icon.svg"
                  onClick={handleHide}
                /> */}
                </div>
              )}
            </div>
            {/* 右區塊 */}
            <div className={`right-box ` + `${hide ? "" : "has-filter "}`}>
              {/* 上區塊 */}
              <div className="tool-box">
                <div className="result-title-box">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      height: "fit-content",
                    }}
                  >
                    <div className="main-title">{t("SEARCH_RESULT")}</div>
                    <div className="sub-title">
                      {data ? "(" + data?.length + ")" : ""}
                    </div>
                  </div>
                  {data && data?.length > 0 && (
                    <>
                      <IconButton
                        imgName={
                          openAll === true
                            ? "double-down-icon.svg"
                            : "double-up-icon.svg"
                        }
                        text={
                          openAll === false
                            ? t("ALL_CONDENSED")
                            : t("EXPAND_ALL")
                        }
                        className="secondary"
                        onClick={handleToggleAll}
                      />
                    </>
                  )}
                </div>

                <div className="tool-item-box">
                  {!hideTool && (
                    <>
                      <LinkIconButton
                        imgName="print-icon.svg"
                        text={t("PRINT_WEB_PAGE")}
                        onClick={() => {
                          handleNewWindow(true);
                        }}
                      />
                      <LinkIconButton
                        imgName="new-window-icon.svg"
                        text={t("OPEN_NEW_WINDOW")}
                        onClick={() => {
                          handleNewWindow(false);
                        }}
                      />
                    </>
                  )}
                  <LinkIconButton
                    imgName="question-icon.svg"
                    text={t("PROBLEM_REPORT")}
                    onClick={() => {
                      setProblemModal({
                        ...problemModal,
                        show: true,
                      });
                    }}
                  />
                  {!hideTool && (
                    <div style={{ paddingTop: "7px" }}>
                      {sustainReportResult?.csvData.length > 0 && (
                        <CSVLink
                          className="d-flex align-items-center"
                          data={sustainReportResult?.csvData}
                          filename={`${t("SUSTAINABILITY_REPORT_INQUIRY")}.csv`}
                          onClick={(e: any) => {}}
                          headers={sustainReportResult?.header}
                        >
                          <LinkIconButton
                            imgName="download-icon.svg"
                            text={t("SAVE_AS_CSV")}
                            onClick={() => {}}
                          ></LinkIconButton>
                        </CSVLink>
                      )}
                    </div>
                  )}
                </div>
                <ProblemReportModal {...problemModal} />
              </div>
              {/* 下區塊 */}
              <div className="result-box scroll">
                {data !== null && data.length > 0 ? (
                  <CompanyComponent
                    data={data}
                    openAll={openAll}
                    year={request.current.year}
                    oldVersion={oldVersion}
                  />
                ) : (
                  <EmptySearchPage {...empty} />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ display: "block", overflowY: "initial" }}
          className={
            `show-result-box scroll ` +
            `${styles["sustainReport-box"]}` +
            `${showResult.print ? " print-box " : ""}`
          }
        >
          <div>
            {tmpResult?.csvData.length > 0 && (
              <CSVLink
                className="d-flex align-items-center"
                data={tmpResult?.csvData}
                filename={`${t("SUSTAINABILITY_REPORT_INQUIRY")}.csv`}
                onClick={(e: any) => {}}
                headers={tmpResult?.header}
              >
                <LinkIconButton
                  imgName="download-icon.svg"
                  text="另存CSV"
                  onClick={() => {}}
                ></LinkIconButton>
              </CSVLink>
            )}
          </div>
          <div
            className={`result-box ` + `${showResult.print ? "" : "scroll "}`}
          >
            {tmpResult &&
            tmpResult.data !== undefined &&
            tmpResult.data !== null &&
            tmpResult.data.length > 0 ? (
              <>
                <CompanyComponent
                  data={tmpResult.data}
                  openAll={openAll}
                  year={request.current.year}
                  oldVersion={oldVersion}
                />
              </>
            ) : (
              <EmptySearchPage {...empty} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

// #endregion 主頁面 =============================

// #region 左邊filter =============================

interface FilterProps {
  searchChange: (param: SustainReportRequestModel) => void;
}
const FilterComponent: FC<FilterProps> = (props) => {
  const { t } = useTranslation();
  const defaultParam = useRef<SustainReportRequestModel>({
    companyCodeList: [],
    year: null,
    industryNameList: [],
    marketType: null,
    industryName: null,
    companyCode: null,
  });
  // 取得搜尋參數
  const [queryParams, setSearchParams] = useSearchParams();
  const [yearOption, setYearOption] = useState<OptionModel[]>([]);
  const [industryOption, setIndustryOption] = useState<OptionModel[]>([]);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState<
    OptionModel[]
  >([]);
  const [companyOption, setCompanyOption] = useState<OptionModel[]>([]);
  const [filterCompanyOption, setFilterCompanyOption] = useState<OptionModel[]>(
    []
  );
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [request, setRequest] = useState<SustainReportRequestModel>({
    marketType: null,
    year: null,
    industryNameList: [],
    companyCodeList: [],
    industryName: "all",
    companyCode: null,
  });
  //#region  外部帶入參數
  useEffect(() => {
    const year = queryParams.get("year")
      ? Number.parseInt(queryParams.get("year")!)
      : null;
    const market = queryParams.get("market")
      ? Number.parseInt(queryParams.get("market")!)
      : null;
    const industry = queryParams.get("industry");
    const companyCode = queryParams.get("companyCode");

    // 有市場別or
    if (market !== null && market !== undefined) {
      // 預設
      defaultParam.current = {
        ...defaultParam.current,
        companyCodeList: [],
        industryNameList: [],
        year: year,
        marketType: market,
        companyCode: !companyCode ? "all" : companyCode,
        industryName: !industry ? "all" : industry,
      };
      getIndustryCompanyCodeMutation(market.toString());

      setRequest({
        ...defaultParam.current,
      });
    }
  }, [queryParams]);

  //#endregion

  const { mutate: getIndustryCompanyCodeMutation, isPending: optionPending2 } =
    useMutation({
      mutationFn: (marketType: string) =>
        SustainReportService.getCompanyCodes(marketType),
      onSuccess: (res) => {
        if (res.code === 200 && res.success) {
          const companyOp = setCompanyOptionModel(res.data.companyNameList);
          const industryOp = setIndustryOptionModel(res.data.industryNameList);
          let defaultQuery = defaultParam.current;
          // 公司清單
          if (defaultQuery.companyCode === "all") {
            defaultQuery.companyCodeList = getNotIncludeAllIds(companyOp, null);
          } else {
            if (defaultQuery.companyCode) {
              defaultQuery.companyCodeList = [defaultQuery.companyCode];
            }
          }
          // 產業別清單

          if (defaultQuery.industryName === "all") {
            defaultQuery.industryNameList = getNotIncludeAllIds(
              industryOp,
              null
            );
          } else {
            if (defaultQuery.industryName) {
              defaultQuery.industryNameList = [defaultQuery.industryName];
            }
          }
          // 如果已經有預設的狀況了直接查詢
          if (!disabledCondition(defaultQuery)) {
            props.searchChange(defaultQuery);
            // 查詢後清空default
            defaultQuery = {
              companyCodeList: [],
              year: null,
              industryNameList: [],
              marketType: null,
              industryName: "all",
              companyCode: null,
            };
          }
        }
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });

  /**設置公司選項model */
  const setCompanyOptionModel = (
    datas: MopsCompanyNameListModel[]
  ): OptionModel[] => {
    let tmpCompany: OptionModel[] = [
      {
        id: "all",
        text: t("ALL_SELECT"),
      },
    ];
    datas.forEach((cp: any) => {
      tmpCompany.push({
        id: cp.companyName.split(" ")[0],
        text: cp.companyName,
        code: cp.industryCode,
      });
    });
    setCompanyOption(tmpCompany);
    setFilterCompanyOption(tmpCompany);
    return tmpCompany;
  };

  /** 設置產業別option*/
  const setIndustryOptionModel = (
    datas: MopsIndustryNameListModel[]
  ): OptionModel[] => {
    const tmpIndustry: OptionModel[] = [
      {
        id: "all",
        text: t("ALL_SELECT"),
      },
    ];
    datas.forEach((indus: any) => {
      tmpIndustry.push({
        id: indus.industryName,
        text: indus.industryName,
        code: indus.industryCode,
      });
    });
    setIndustryOption(tmpIndustry);
    return tmpIndustry;
  };

  /**改變市場別 */
  const handleMarketTypeChange = (value: string | null) => {
    let tmpreq = { ...request };
    if (value) {
      const market = Number.parseInt(value);
      tmpreq = {
        ...tmpreq,
        marketType: market,
        companyCodeList: [],
        industryNameList: [],
        industryName: "all",
        companyCode: null,
      };
      // 預設清空
      defaultParam.current = {
        ...defaultParam.current,
        ...tmpreq,
      };

      getIndustryCompanyCodeMutation(value);
    } else {
      tmpreq = {
        ...tmpreq,
        marketType: null,
        companyCodeList: [],
        industryNameList: [],
        industryName: "all",
        companyCode: null,
      };
      // 預設清空
      defaultParam.current = {
        ...defaultParam.current,
        ...tmpreq,
      };
    }

    setRequest(tmpreq);
  };

  /**改變年度 */
  const handleYearsChange = (e: string | null) => {
    if (e) {
      let tmpreq = { ...request };
      tmpreq.year = parseInt(e, 10);
      setRequest(tmpreq);
    }
  };

  /** 改變產業別 */
  const handleIndustryChange = (id: string | null) => {
    let tmpreq = { ...request };
    if (id) {
      // 全選
      if (id === "all") {
        tmpreq.industryNameList = getNotIncludeAllIds(industryOption, null);
        setFilterCompanyOption(companyOption);
        setSelectedIndustryOption(industryOption);
      } else {
        tmpreq.industryNameList = [id];
        const selectedOption = industryOption.find(
          (option) => option.id === id
        );
        if (selectedOption && selectedOption.code) {
          let filterResult = CommonService.deepClone(
            companyOption.filter(
              (companyOption) => companyOption.code === selectedOption.code
            )
          );
          let tmpCompany: OptionModel[] = [
            {
              id: "all",
              text: t("ALL_SELECT"),
            },
          ];
          setFilterCompanyOption(tmpCompany.concat(filterResult));
          setSelectedIndustryOption([selectedOption]);
        }
      }
      tmpreq.industryName = id;
      tmpreq.companyCode = null;
      tmpreq.companyCodeList = [];
    } else {
      tmpreq.industryNameList = [];
      tmpreq.companyCodeList = [];
      tmpreq.companyCode = null;
      tmpreq.industryName = null;
    }
    setRequest(tmpreq);
  };

  /** 改變公司代號 */
  const handleCompanyChange = (id: string | null) => {
    let tmpreq = { ...request };
    if (id) {
      // 單一產業別全選
      if (selectedIndustryOption.length === 1) {
        // 單一公司
        if (id !== "all") {
          tmpreq.companyCodeList = [id];
        } else {
          // 全選公司
          tmpreq.companyCodeList = getNotIncludeAllIds(
            companyOption,
            selectedIndustryOption[0].code as string
          );
        }
      }
      // 全產業別&全選公司
      else if (id === "all") {
        tmpreq.companyCodeList = getNotIncludeAllIds(companyOption, null);
      } else {
        tmpreq.companyCodeList = [id];
      }
      tmpreq.companyCode = id;
    } else {
      tmpreq.companyCodeList = [];
      tmpreq.companyCode = null;
    }
    setRequest(tmpreq);
  };

  /** 不包含all 的清單 */
  const getNotIncludeAllIds = (list: OptionModel[], code: string | null) => {
    if (!code) {
      return [];
    }
    return list
      .filter((p) => p.id !== "all" && (code === null || p.code === code))
      .map((p) => p.id);
  };

  useEffect(() => {
    if (request) {
      disabledCondition(request);
    }
  }, [request]);

  /** disabled 狀況 */
  const disabledCondition = (param: SustainReportRequestModel): boolean => {
    let disabled = false;
    console.log(param.companyCode || param.companyCodeList.length > 0);
    // 單一欄位or 陣列其一有值即可
    if (
      param.year !== null &&
      param.marketType !== null &&
      (param.companyCode || param.companyCodeList.length > 0) &&
      (param.industryName || param.industryNameList.length > 0)
    ) {
      disabled = false;
    } else {
      disabled = true;
    }
    setBtnDisabled(disabled);
    return disabled;
  };

  //取得年度 新版
  const getYearList = useMutation({
    mutationFn: (type: RequestType) => mopsEsgService.getYearList(type),
    onSuccess: (res) => {
      if (res.code === 200 && res.success) {
        const tmpYears: OptionModel[] = [];
        res.data.forEach((yr: number) => {
          tmpYears.push({ id: yr.toString(), text: yr.toString() });
        });
        setYearOption(tmpYears);
      } else {
        setYearOption([]);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    getYearList.mutate(RequestType.SustainReport);
  }, []);

  return (
    <>
      {optionPending2 ? <VisuallLoading /> : <></>}
      <div className="mt-3">
        <Dropdown
          placeholder={`${t("MARKET_TYPE")}*`}
          options={MarketOp}
          defaultId={request.marketType?.toString()}
          onChange={handleMarketTypeChange}
        />
      </div>
      <div className="mt-3">
        <Dropdown
          placeholder={`${t("REPORTING_YEAR")}*`}
          options={yearOption}
          defaultId={request.year?.toString()}
          onChange={handleYearsChange}
        />
      </div>
      <div className="mt-3">
        <Dropdown
          placeholder={t("INDUSTRY_TYPE")}
          options={industryOption}
          defaultId={request.industryName}
          isFloatTitle={true}
          onChange={handleIndustryChange}
        />
      </div>
      <div className="mt-3">
        <Dropdown
          placeholder={t("COMPANY_CODE")}
          options={filterCompanyOption}
          defaultId={request.companyCode}
          isFloatTitle={true}
          onChange={handleCompanyChange}
        />
      </div>

      <div className="mt-3">
        <button
          onClick={() => {
            props.searchChange(request);
          }}
          disabled={btnDisabled === true}
          className={`default ` + `${btnDisabled ? "disabled " : ""}`}
        >
          {t("INQUIRY")}
        </button>
      </div>
    </>
  );
};

// #endregion 左邊filter ==========================

//#region 空元件 =============================
const EmptyComponent: FC<any> = ({ word }) => {
  return <div className="empty-box">{word}</div>;
};
//#endregion 空元件 =============================

//#region 清單內容區塊 =============================
interface CompanyProps {
  data: SustainReportModel[];
  openAll: boolean;
  year: number | null;
  oldVersion: boolean;
}

const CompanyComponent: FC<CompanyProps> = (props) => {
  const [list, setList] = useState<SustainReportModel[]>(props.data);
  const [realYear, setRealYear] = useState<number>(0);
  const { t } = useTranslation();
  const uuidAllZero = "00000000-0000-0000-0000-000000000000";
  useEffect(() => {
    if (props.data) {
      let newList = props.data.map((i) => {
        i.hide = props.openAll;
        return i;
      });
      setList(newList);
    }
  }, [props.data, props.openAll]);

  useEffect(() => {
    if (props.year !== null) {
      setRealYear(props.year);
    } else {
      setRealYear(0);
    }
  }, [props.year]);

  // 更新隱藏
  const hideDetail = (data: SustainReportModel) => {
    setList(
      list.map((i) => {
        if (i.code === data.code) {
          i.hide = !data.hide;
        }
        return i;
      })
    );
  };

  //下載pdf stream
  const { mutate: getReportStreamMutation, isPending: pdfPending } =
    useMutation({
      mutationFn: (fileId: string) =>
        SustainReportService.getFileStream(fileId),
      onSuccess: (res) => {
        if (res.status !== ResponseCode.ServerErrorInternal) {
          CommonService.downloadByStream(res);
        } else {
          error2Alert(res.data.message);
        }
      },
      onError: (err) => {
        error2Alert(t("NO_FILE"));
        console.log(err.message);
      },
    });

  /** 下載檔案stream */
  const dowloadFileStream = (fileId: string | null) => {
    if (fileId) {
      // 舊版直接url download
      if (props.oldVersion) {
        CommonService.downloadByUrl(fileId);
      } else {
        getReportStreamMutation(fileId);
      }
    }
  };

  /** 寄信 */
  const mailTo = (mail: string | null) => {
    if (mail) {
      let doc = document.createElement("a");
      doc.href = `mailto:${mail}`;
      document.body.appendChild(doc);
      doc.click();
      document.body.removeChild(doc);
    }
  };

  //#region 依不同年度呈現不同component
  /** 依不同年度 */
  const DataTableComponent: FC<{ item: SustainReportModel }> = (props) => {
    const { item } = props;
    /** 陣列titles */
    const [titles, setTitles] = useState<string[]>([]);
    useEffect(() => {
      if (realYear !== 0 && realYear !== null) {
        if (realYear <= 2013) {
          setTitles([
            t("COMPANY_CODE"),
            t("COMPANY_NAME"),
            t("REPORT_COMPLETE_DATE"),
            t("PERIOD_COVERED_BY_REPORT"),
          ]);
        } else if (realYear >= 2014 && realYear <= 2019) {
          setTitles([
            t("COMPANY_CODE"),
            t("COMPANY_NAME"),
            t("REASON_FOR_DECLARATION"),
            t("REPORT_COMPLETE_DATE"),
            t("PERIOD_COVERED_BY_REPORT"),
          ]);
        } else {
          setTitles([
            t("COMPANY_CODE"),
            t("COMPANY_NAME"),
            t("ENGLISH_ABBREVIATION"),
            t("REASON_FOR_DECLARATION"),
            t("INDUSTRY_CATEGORY"),
            t("PERIOD_COVERED_BY_REPORT"),
          ]);
        }
      }
    }, [realYear]);

    return (
      <>
        {/* 共用欄位 */}
        <div>
          <table
            aria-label={t("SEARCH_RESULT")}
            className="table-container inquiry-table"
          >
            <thead>
              <tr>
                {titles.map((title, titleIndex) => {
                  return <th key={titleIndex}>{title}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {realYear <= 2013 && (
                <tr>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>{item.fdate}</td>
                  <td>{item.reportingInterval}</td>
                </tr>
              )}
              {realYear >= 2014 && realYear <= 2019 && (
                <tr>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>{item.reason}</td>
                  <td>{item.fdate}</td>
                  <td>{item.reportingInterval}</td>
                </tr>
              )}
              {realYear >= 2020 && (
                <tr>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>{item.enName}</td>
                  <td>{item.reason}</td>
                  <td>{item.sector}</td>
                  <td>{item.reportingInterval}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* 各自年度特殊欄位 */}
        <div className="mt-16-p">
          <table
            aria-label={t("SEARCH_RESULT")}
            className="table-container inquiry-table"
          >
            <thead>
              {realYear <= 2013 && (
                <tr>
                  <th scope="col">
                    {t("COMPILED_ACCORDING_TO_THE_GUIDELINES")}
                  </th>
                  <th scope="col">{t("CERTIFICATION_UNIT")}</th>
                  <th scope="col">{t("VERIFICATION_METHOD")}</th>
                </tr>
              )}
              {realYear >= 2014 && realYear <= 2019 && (
                <tr>
                  <th scope="col">
                    {t("COMPILED_ACCORDING_TO_THE_GUIDELINES")}
                  </th>
                  <th scope="col">
                    {t("GUARANTEE")}/{t("CONFIRMATION")}
                  </th>
                </tr>
              )}
              {realYear >= 2020 && realYear <= 2022 && (
                <>
                  <tr>
                    <th scope="rowgroup" rowSpan={2}>
                      {t("COMPILED_ACCORDING_TO_THE_GUIDELINES")}
                    </th>
                    <th scope="colgroup" colSpan={3}>
                      {t("THIRD_PARTY_VERIFICATION2")}
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">{t("VERIFICATION_UNIT")}</th>
                    <th scope="col">{t("UNIT_NAME")}</th>
                    <th scope="col">{t("ADOPT_STANDARDS")}</th>
                  </tr>
                </>
              )}
              {realYear >= 2023 && (
                <>
                  <tr>
                    <th scope="rowgroup" rowSpan={2} className="w120-p">
                      {t("COMPILED_ACCORDING_TO_THE_GUIDELINES")}
                    </th>
                    <th scope="rowgroup" colSpan={2}>
                      {t("THIRD_PARTY_VERIFICATION")}
                    </th>
                    <th scope="rowgroup" colSpan={3}>
                      {t("ACCOUNTANT_CONVINCED")}
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">{t("VERIFICATION_UNIT")}</th>
                    <th scope="col">{t("ADOPT_STANDARDS")}</th>
                    <th scope="col">{t("VERIFICATION_UNIT")}</th>
                    <th scope="col">{t("ADOPT_STANDARDS")}</th>
                    <th scope="col">{t("OPINION_TYPE")}</th>
                  </tr>
                </>
              )}
            </thead>
            <tbody>
              {realYear <= 2013 && (
                <tr>
                  <td>{item.complianceNotes}</td>
                  <td>{item.valiProvider}</td>
                  <td>{item.thirdStd}</td>
                </tr>
              )}
              {realYear >= 2014 && realYear <= 2019 && (
                <tr>
                  <td>{item.complianceNotes}</td>
                  <td>{item.stdCompliance}</td>
                </tr>
              )}
              {realYear >= 2020 && realYear <= 2022 && (
                <tr>
                  <td>{item.complianceNotes}</td>
                  {/* 驗證單位 */}
                  <td>{item.valiProvider}</td>
                  {/* 單位名稱 */}
                  <td>{item.partnerOrganization}</td>
                  {/* 採用標準 */}
                  <td>{item.thirdStd}</td>
                </tr>
              )}
              {realYear >= 2023 && (
                <tr>
                  <td>{item.complianceNotes}</td>
                  <td>{item.valiProvider}</td>
                  <td>{item.thirdStd}</td>
                  <td>{item.partnerOrganization}</td>
                  <td>{item.stdCompliance}</td>
                  <td>{item.accOptionType}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  };
  //#endregion

  return (
    <div>
      {pdfPending && <VisuallLoading />}
      {list.map((item, i) => {
        return (
          <div key={`${item.code}_${i}`} className="companyItem">
            <div
              className="section-title"
              style={{ display: "flex" }}
              onClick={() => hideDetail(item)}
            >
              <div>
                <img
                  alt="extend"
                  className={item.hide ? "hide" : ""}
                  src="/assets/images/declaration/application/extend-up-icon.svg"
                />
              </div>
              <div>
                {item.code}-{item.shortName}
              </div>
            </div>
            {!item.hide && (
              <div className="section-content">
                {(item.twStatus === 2 || item.twStatus === 3) && (
                  <>
                    {/* 前兩個table 欄位會動態調整(中文相關) */}
                    <DataTableComponent item={item}></DataTableComponent>

                    {/* ---------------------------------- */}
                    {/* 以下新舊版本欄位title欄位不更動(中文相關) */}
                    <div className="section-subtitle">
                      {t("CHINESE_VERSION_REPORT")}：
                    </div>
                    <div className="mt-16-p">
                      <table
                        aria-label={t("SEARCH_RESULT")}
                        className="table-container inquiry-table"
                      >
                        <thead>
                          <tr>
                            <th>{t("REPORT_URL")}</th>
                            <th>{t("REPORT_FILE")}</th>
                            <th>{t("UPLOAD_DATE")}</th>
                            <th>{t("AMENDED_REPORT")}</th>
                            <th>{t("AMENDED_REPORT_UPLOAD_DATE")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {item.twDocLink && (
                                <LinkIconButton
                                  imgName="external-icon.svg"
                                  text={t("COMPANY_WEBSITE")}
                                  onClick={() => {
                                    /*const downloadUrl = item.twDocLink;
                                  const link = document.createElement("a");
                                  link.href = downloadUrl;
                                  link.target = "_blank";
                                  document.body.appendChild(link);
                                  link.click();
                                  link.remove();*/
                                    if (item.twDocLink) {
                                      const realUrl =
                                        CommonService.getProtocolUrl(
                                          item.twDocLink || ""
                                        );
                                      window.open(
                                        realUrl,
                                        "_blank",
                                        "noopener noreferrer"
                                      );
                                    }
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {item.twFirstReportDownloadId &&
                                item.twFirstReportDownloadId !==
                                  uuidAllZero && (
                                  <LinkIconButton
                                    imgName="download-icon.svg"
                                    text={`${t("DOWNLOAD")}PDF`}
                                    onClick={() => {
                                      dowloadFileStream(
                                        item.twFirstReportDownloadId
                                      );
                                    }}
                                  />
                                )}
                            </td>
                            <td>{normalDate(item.twCommencementDate)}</td>
                            <td>
                              {item.twEditReportDownloadId &&
                                item.twEditReportDownloadId !== uuidAllZero && (
                                  <LinkIconButton
                                    imgName="download-icon.svg"
                                    text={`${t("DOWNLOAD")}PDF`}
                                    onClick={() => {
                                      dowloadFileStream(
                                        item.twEditReportDownloadId
                                      );
                                    }}
                                  />
                                )}
                            </td>
                            <td>{normalDate(item.twRevisionDate)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

                {/* 以下新舊版本欄位title欄位不更動(英文相關) */}
                {(item.enStatus === 2 || item.enStatus === 3) && (
                  <>
                    <div className="section-subtitle">
                      {t("ENGLISH_VERSION_REPORT")}：
                    </div>
                    <div className="mt-16-p">
                      <table
                        aria-label={t("SEARCH_RESULT")}
                        className="table-container inquiry-table"
                      >
                        <thead>
                          <tr>
                            <th>{t("REPORT_URL")}</th>
                            <th>{t("REPORT_FILE")}</th>
                            <th>{t("UPLOAD_DATE")}</th>
                            <th>{t("AMENDED_REPORT")}</th>
                            <th>{t("AMENDED_REPORT_UPLOAD_DATE")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {item.enDocLink && (
                                <LinkIconButton
                                  imgName="external-icon.svg"
                                  text={t("COMPANY_WEBSITE")}
                                  onClick={() => {
                                    /*const downloadUrl = item.enDocLink;
                                    const link = document.createElement("a");
                                    link.href = downloadUrl;
                                    link.target = "_blank";
                                    document.body.appendChild(link);
                                    link.click();
                                    link.remove();*/
                                    if (item.enDocLink) {
                                      const realUrl =
                                        CommonService.getProtocolUrl(
                                          item.enDocLink || ""
                                        );
                                      window.open(
                                        realUrl,
                                        "_blank",
                                        "noopener noreferrer"
                                      );
                                    }
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {item.enFirstReportDownloadId &&
                                item.enFirstReportDownloadId !==
                                  uuidAllZero && (
                                  <LinkIconButton
                                    imgName="download-icon.svg"
                                    text={`${t("DOWNLOAD")}PDF`}
                                    onClick={() => {
                                      dowloadFileStream(
                                        item.enFirstReportDownloadId
                                      );
                                    }}
                                  />
                                )}
                            </td>
                            <td>{normalDate(item.enCommencementDate)}</td>
                            <td>
                              {item.enEditReportDownloadId &&
                                item.enEditReportDownloadId !== uuidAllZero && (
                                  <LinkIconButton
                                    imgName="download-icon.svg"
                                    text={`${t("DOWNLOAD")}PDF`}
                                    onClick={() => {
                                      dowloadFileStream(
                                        item.enEditReportDownloadId
                                      );
                                    }}
                                  />
                                )}
                            </td>
                            <td>{normalDate(item.enRevisionDate)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

                {/* 以下新舊版本欄位title欄位不更動(中文的備註) */}
                {(item.twStatus === 2 || item.twStatus === 3) && (
                  <div className="mt-16-p">
                    <table
                      aria-label={t("SEARCH_RESULT")}
                      className="table-container inquiry-table"
                    >
                      <thead>
                        <tr>
                          <th>{t("REPORT_CONTACT_INFORMATION")}</th>
                          <th>{t("NOTE")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <LinkIconButton
                              imgName="external-icon.svg"
                              text={item.contactInfo}
                              onClick={() => {
                                /*item.contactInfo*/
                                mailTo(item.contactInfo);
                              }}
                            />
                          </td>
                          <td>{item.note}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
//#endregion 空元件 =============================

export default SustainReport;
